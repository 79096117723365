<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div class="result" v-if="detailData">
    <!--    <img class="bgimg" src="https://cdn.qiandaoapp.com/interior/images/801f16c9a4840c49fccad19e481888f8.png!lfit_w1080_h1080_png" alt="">-->
    <div class="bgimg" />
    <div class="result-content">
      <div class="result-content-cover" v-if="detailData?.covers?.[0]?.url">
        <van-image
          fit="cover"
          class="result-content-cover-img"
          :src="detailData.covers[0].url + '!mfit_w750_h750_jpg'"
          alt=""
        />
      </div>
      <div class="result-content-desc">
        <div v-if="transStauts === 'failure'" class="info-item info-failure">
          <div class="info-item-title">{{ detailData.name }} 交易失败</div>
        </div>
        <div
          v-else-if="transStauts === 'success'"
          class="info-item info-success"
        >
          <div class="info-item-title">你已经成功拥有{{ detailData.name }}</div>
          <!--          <span class="check-status-text"-->
          <!--            >交易{{ transHash.slice(-14) }}已在</span-->
          <!--          >-->
          <!--          <span class="gap" />-->
          <!--          <Polygon />-->
          <!--          <span class="gap" />-->
          <!--          <span class="check-status-polygon">polygon</span>-->
          <!--          <span class="check-status-text">链上被确认</span>-->
          <div class="info-item-btn">
            <main-button @onInvokeApp="handleToPolygon" text="立即查看" />
          </div>
          <!--            .slice(-14)-->
        </div>
        <div v-else class="check">
          <div class="check-title">正在将作品转交给你...</div>
          <!--          <div class="check-status">-->
          <!--            <span class="check-status-text">作品的所有权在</span>-->
          <!--            <span class="gap" />-->
          <!--            <Polygon />-->
          <!--            <span class="gap" />-->
          <!--            <span class="check-status-polygon">polygon</span>-->
          <!--            <span class="check-status-text">链上被存证</span>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { Image as VanImage, showFailToast, showConfirmDialog } from "vant";
import qs from "qs";
import { useRouter } from "vue-router";
import { getQuery } from "@/utils/url";
import { SPEND_MONEY_TYPE } from "@/constant/payment";
import NftApi from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import Polygon from "@/components/Polygon/index.vue";
import MainButton from "@/components/MainButton";

const router = useRouter();
const localQuery = getQuery();

const detailData = ref();
const localOrderInfo = ref();
const transStauts = ref("");
const transHash = ref("");
const timeRef = ref();

const handleQuery = async () => {
  const { orderInfo } = localQuery || {};
  const tempOrderInfo = qs.parse(decodeURIComponent(orderInfo) || "");
  const { spendMoneyType, tokenId, orderId } = tempOrderInfo;
  localOrderInfo.value = tempOrderInfo;

  if (spendMoneyType === SPEND_MONEY_TYPE.JOIN_BID) {
    router.replace(`/token-detail?tokenId=${tokenId}`);
    // window.location.href = `${location.origin}/token-detail?tokenId=${tokenId}`;
  } else if (spendMoneyType === SPEND_MONEY_TYPE.PURCHASING) {
    checkTransStatus(orderId);
    timeRef.value = setInterval(() => {
      checkTransStatus(orderId);
    }, 3000);

    const res = await NftApi.getTokenDetail({ tokenId });
    const { data } = res;
    console.log("res===", res);
    if (res.code !== REQUEST_OK) {
      showFailToast("查询token失败");
      return;
    }
    // detailData = reactive(data.token || {})
    detailData.value = data?.token || {};
    console.log("detailData----", detailData.value);
  }
};
// spendMoneyType=PURCHASING&tokenId=569052118405615207&orderId=569155777441311639

const checkTransStatus = async (orderId) => {
  const transRes = await NftApi.checkTransStaut({ orderId });
  if (transRes.code !== REQUEST_OK) {
    showFailToast("查询交易进度失败");
    return;
  }
  const { status, txnHash } = transRes.data || {};
  console.log("transStauts----", status);
  transStauts.value = status;
  transHash.value = txnHash;
  // transStauts.value = 'success'
  // transHash.value = "dfklasfjdklajf";
  if (status === "success") {
    clearInterval(timeRef.value);
  }
};

const handleToPolygon = () => {
  let tempQuery = "";
  if (localOrderInfo.value.productType) {
    tempQuery += `&productType=${localOrderInfo.value.productType}`;
  }
  router.replace(
    `/token-detail?tokenId=${localOrderInfo.value.tokenId}${tempQuery}`
  );
};

handleQuery();
</script>

<style lang="scss" scoped>
.result {
  background: #000000;
  //max-width: 480px;
  width: 100%;
  height: 100%;
  .bgimg {
    position: absolute;
    bottom: 0;
    left: 0;
    //max-width: 480px;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("https://cdn.qiandaoapp.com/interior/images/801f16c9a4840c49fccad19e481888f8.png!lfit_w1080_h1080_png");
    //background-image: url("https://cdn.qiandaoapp.com/ued/4a4a6ef9d5a56a2bc1eadbc16effd856.png!lfit_w1080_h1080_png");
  }
  &-content {
    position: relative;
    &-cover {
      margin: 280rpx auto 0 auto;
      width: 490rpx;
      height: 490rpx;
      border-radius: 48rpx;
      overflow: hidden;
      &-img {
        width: 100%;
        height: 100%;
      }
    }
    &-desc {
      margin-top: 80rpx;
      .check {
        &-title {
          font-weight: 800;
          font-size: 48rpx;
          line-height: 64rpx;
          color: #ffffff;
          text-align: center;
        }
        &-status {
          margin-top: 16rpx;
          display: flex;
          align-items: center;
          justify-content: center;
          .gap {
            margin-left: 8rpx;
          }
          &-text {
            font-weight: 400;
            font-size: 32rpx;
            line-height: 48rpx;
            color: rgba(255, 255, 255, 0.5);
          }
          &-polygon {
            margin-right: 8rpx;
            font-weight: bold;
            color: #ffffff;
          }
        }
        //&-result {
        //  &-title {
        //    font-weight: 800;
        //    font-size: 48rpx;
        //    line-height: 64rpx;
        //    color: #ffffff;
        //  }
        //}
      }
      .info-item {
        text-align: center;
        &-title {
          font-weight: 800;
          font-size: 48rpx;
          line-height: 64rpx;
          color: #ffffff;
          text-align: center;
        }
        &-btn {
          margin-top: 72rpx;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
